export const data = [
    {
        area: 1,
        order: 1,
        year: 1952,
        titleText: "  Sunil Perera was born on September 14 in Moratuwa, Sri Lanka",
        descriptionText: "He had three brothers and one sister.",
        mediaURL: "",
    },
    {
        area: 1,
        order: 2,
        year: 1970,
        titleText: "  Sunil Perera, along with his brothers and friends, formed the band Gypsies",
        descriptionText: "The band was funded by their father",
        mediaURL: "https://mullenlowesandboxstorage.z23.web.core.windows.net/SLLsite/LindaLanga.mp3",
    },
    {
        area: 1,
        order: 3,
        year: 1972,
        titleText: "First hit Linda Langa Sangamaya was released in 1972/1973 along with Amma Amma Me Mata",
        descriptionText: "It went to #1 on the billboards",
        mediaURL: "",
    },
    {
        area: 1,
        order: 4,
        year: 1980,
        titleText: "The group released their first audio cassette containing their novelty hit Kurumitto",
        descriptionText: "The cassettes sold out in 2 days",
        mediaURL: "https://mullenlowesandboxstorage.z23.web.core.windows.net/SLLsite/Kurumitto.mp3",
    },
    {
        area: 1,
        order: 5,
        year: 1987,
        titleText: "Released 'Lunu Dehi' and 'Uncle Johnson'",
        descriptionText: "International recognition for Sunil and the Gypsies",
        mediaURL: "https://mullenlowesandboxstorage.z23.web.core.windows.net/SLLsite/LunuDehi.mp3",
    },
    {
        area: 1,
        order: 6,
        year: 1989,
        titleText: "Released Oye Ojaye",
        descriptionText: "First platinum record",
        mediaURL: "",
    },
    {
        area: 2,
        order: 1,
        year: 2002,
        titleText: "Title-Test-1",
        descriptionText: "Description-Test-1",
        mediaURL: "",
    },
    {
        area: 2,
        order: 2,
        year: 2010,
        titleText: "Title-Test-2",
        descriptionText: "Description-Test-2",
        mediaURL: "",
    },
    {
        area: 2,
        order: 3,
        year: 2020,
        titleText: "Title-Test-3",
        descriptionText: "Description-Test-3",
        mediaURL: "",
    },
];