import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';

const Header = () => {
  return (
    <Link to="/" className="header">
      {'<<'}
    </Link>
  );
};

export default Header;
