import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { data } from "../dataSet/text";
import Header from '../header/Header';
import './detail.css';
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs';

function Detail_1() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlers = useSwipeable({
    onSwipedUp: () => {
      if (currentIndex < filteredData.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }
      setAnimate(true);
    },
    onSwipedDown: () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
      setAnimate(true);
    },
  });

  useEffect(() => {
    if (animate) {
      const timeout = setTimeout(() => {
        setAnimate(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [animate]);

  const handleAudioToggle = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const filteredData = data.filter((item) => item.area === 1);
  const currentItem = filteredData[currentIndex];
  const previousList = filteredData.slice(0, currentIndex);

  return (
    <div className="detail" {...handlers}>
      <Header />
      <Helmet>
        <title>Sunil-Perera-1952</title>
      </Helmet>
      <AnimatePresence>
        <motion.div
          key={currentIndex}
          className="content"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5 }}
        >
          <div>
            <h2 className="cadet">
              SUNIL PERERA
              <br />
              <span className="dbirth">1952 - 2001</span>
            </h2>
          </div>

          <div>
            <ul className="list">
              {previousList.map((item, index) => (
                <li key={index}>{item.titleText}</li>
              ))}
            </ul>
            <motion.h1
              className={`year ${animate ? "visible" : "hidden"}`}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1 }}
              variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}
            >
              {currentItem.year}
            </motion.h1>
            <motion.h1
              className={`description ${animate ? "visible" : "hidden"}`}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 0.2 }}
              variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}
            >
              {currentItem.titleText}
            </motion.h1>
            <motion.h4
              className={`about ${animate ? "visible" : "hidden"}`}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 0.4 }}
              variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}
            >
              {currentItem.descriptionText}
            </motion.h4>
            {currentIndex < filteredData.length - 1 && (
              <div>
                <ul className="list">
                  {filteredData.slice(currentIndex + 1, currentIndex + 6).map((listItem, listIndex) => (
                    <li key={listIndex}>{listItem.titleText}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      {currentItem.mediaURL && (
        <div className="music-container">
          <audio ref={audioRef} src={currentItem.mediaURL}></audio>
          <button className="music_button" onClick={handleAudioToggle}>
            {isPlaying ? <BsFillPauseFill /> : <BsFillPlayFill />}
          </button>
        </div>
      )}
    </div>
  );
}

export default Detail_1;
