import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';

const HomePage = () => {
  return (
    <p
      className="home-container"
      style={{
        backgroundImage: `url("/homescreen.jpg")`,
      }}
    >
      <Link to="/detail_1" className="link-left">
        {/* Content for left side */}
      </Link>
      <Link to="/detail_1" className="link-right">
        {/* Content for right side */}
      </Link>
    </p>
  );
};

export default HomePage;
